import { theme } from 'src/styles/theme';
import styled from 'styled-components';

export const Container = styled.div<{ isActive: boolean }>`
  .wrapper {
    max-width: 454px;
    width: 100%;
    padding: 8px 0;
    position: relative;
    &:after {
      content: '';
      display: block;
      height: 2px;
      width: ${({ isActive }) => (isActive ? '100%' : '50%')};
      background: ${({ isActive }) =>
        isActive ? theme.colors.green : 'transparent'};
      transition: width 0.5s ease, background-color 0.5s ease;
      position: absolute;
      bottom: 0;
    }
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .step-button {
      width: 100%;
      text-align: left;
      cursor: pointer;
    }
    .step-number {
      text-transform: uppercase;
    }
    .step-number,
    .step-title,
    svg {
      color: ${({ isActive }) =>
        isActive ? theme.colors.green : theme.colors.navy};
      transition: color 0.5s ease;
    }
    .step-title {
      letter-spacing: normal;
    }
    svg {
      font-size: 16px;
      position: absolute;
      &.arrow-right {
        opacity: ${({ isActive }) => (isActive ? 1 : 0)};
        right: ${({ isActive }) => (isActive ? 0 : '25%')};
      }
      &.arrow-down,
      &.arrow-up {
        right: 0;
        color: ${theme.colors.green};
      }
      transition: right 0.5s ease, opacity 0.5s ease, color 0.5s ease;
    }
    .arrow-down,
    .arrow-up {
      display: none;
    }

    @media (max-width: 1280px) {
      max-width: 100%;
      padding-right: 18px;
    }

    @media (max-width: 640px) {
      max-width: none;
      margin: 16px 0;
      .arrow-right {
        display: none;
      }
      .arrow-down,
      .arrow-up {
        display: block;
      }
    }
  }
  .card-wrapper {
    display: none;
  }

  @media (max-width: 640px) {
    .card-wrapper {
      display: block;
    }
  }
`;
