import styled from 'styled-components';
import Slider from 'react-slick';

export const StyledSliderContent = styled(Slider)`
  .slick-list {
    overflow: visible;
    clip-path: inset(0 0 -100vw -100vw);
  }
`;
export const CardWrapper = styled.div`
  padding: 0 8px !important;
`;
