import styled from 'styled-components';

export const ProcessTypeSelectContainer = styled.div`
  .process-type-select {
    font-size: 32px;
    font-weight: 700;
    &__control {
      background-color: transparent;
      border: none;
      width: max-content;
      min-width: 185px;
      box-shadow: none;

      .process-type-select__indicator {
        transition: transform 0.3s ease;
      }

      &--menu-is-open {
        .process-type-select__indicator {
          transform: rotateX(180deg);
        }
      }
    }

    &__value-container {
      padding: 0;
    }

    &__option {
      font-size: 2rem;
      padding: 2rem;
      &--is-selected {
        background-color: ${({ theme }) => theme.colors.lightGreen3};
        color: ${({ theme }) => theme.colors.green};
        position: relative;
        &::after {
          background-image: url('/img/home-page/commons/green-check-mark.svg');
          content: '';
          color: #ffffff;
          position: absolute;
          right: 2rem;
          top: 50%;
          transform: translateY(-50%);
          width: 20px;
          height: 20px;
        }
      }
      &--is-focused {
        background-color: ${({ theme }) => theme.colors.lightGreen3};
      }
    }
    &__single-value {
      color: ${({ theme }) => theme.colors.green};
    }

    &__menu-list {
      padding: 0;
    }
    &__menu {
      width: max-content;
      min-width: 300px;
      border-radius: 0.8rem;
      overflow: hidden;
      color: ${({ theme }) => theme.colors.green};
    }

    &__indicator,
    &__indicator:hover {
      color: ${({ theme }) => theme.colors.lightGreen2};
      padding: 0;
      svg {
        width: 30px;
        height: 30px;
      }
    }
    &__indicator-separator {
      display: none;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    .process-type-select {
      &__menu {
        min-width: 180px;
      }
    }
  }
`;
