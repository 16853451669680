import { useCallback, useEffect, useState } from 'react';

import { useFeature } from '@growthbook/growthbook-react';
import { Col, GridContainer, H2, H4 } from 'components/lib';
import Image from 'next/image';
import Arch from 'public/img/landing-page/offer-ready/arch_bot.png';
import { LoanOfficer, ProcessOptions } from 'src/common/types';
import { Lang } from 'src/data/lang/our-process-what-need.lang';
import Card from './Card';
import { Container } from './our-process-styles';
import ProcesssTypeSelect from './ProcessTypeSelect/ProcesssTypeSelect';
import Step from './Step';

interface STEP {
  title: string;
  body: string;
  options: string[];
  image: string;
}

const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const setInitialOptions = (steps: STEP[]): boolean[][] => {
  const stepOptions: boolean[][] = [];
  steps.map(step => stepOptions.push(Array(step.options.length).fill(false)));
  return stepOptions;
};

const OurProcess = ({ officer }: { officer?: LoanOfficer }) => {
  const newDropDown = useFeature('our-process-dropdown');
  const [content, setContent] = useState(Lang.Purchase);
  const [step, setStep] = useState<number>(-1);
  const [isChanging, setIsChanging] = useState<boolean>(false);
  const { title, steps, dropdownSubtitle } = content;
  const [stepOptions, setStepOptions] = useState<boolean[][]>(
    setInitialOptions(steps)
  );

  const handleProcessTypeChange = (option: ProcessOptions) => {
    if (newDropDown.off) return;
    setContent(Lang[option]);
    setStep(0);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > 640) {
        setStep(0);
      }
    }
  }, []);

  const handleChanging = useCallback(
    async (_step: number) => {
      if (typeof window !== 'undefined') {
        if (window.innerWidth <= 640) {
          setStep(step === _step ? -1 : _step);
        } else {
          setIsChanging(true);
          await sleep(500);
          setIsChanging(false);
          setStep(_step);
        }
      }
    },
    [step]
  );

  const handleSetStepOptions = useCallback(
    (options: boolean[]) => {
      const tmpOptions = [...stepOptions];
      tmpOptions[step] = options;
      setStepOptions(tmpOptions);
    },
    [step, stepOptions, setStepOptions]
  );

  const validStep = step === -1 ? 0 : step;

  const { body, options, image } = steps[validStep];

  return (
    <GridContainer>
      <Col span="2/12" smallSpan="1/13" mediumSpan="1/13">
        <Container isChanging={isChanging}>
          <div className="our-process">
            <div className="steps">
              {newDropDown.on ? (
                <div className="dropdown">
                  <ProcesssTypeSelect setContent={handleProcessTypeChange} />
                  <H4>{dropdownSubtitle}</H4>
                </div>
              ) : (
                <H2>{title}</H2>
              )}
              {steps.map((s, idx) => (
                <Step
                  key={s.image}
                  current={idx}
                  active={step}
                  title={s.title}
                  onSelect={handleChanging}
                  card={{
                    body,
                    options,
                    stepOptions: stepOptions[validStep],
                    setStepOptions: handleSetStepOptions,
                  }}
                />
              ))}
            </div>
            <div className="content">
              <div className="bg-img">
                <Image
                  src={image}
                  alt="Step Content"
                  quality={100}
                  fill
                  sizes="100vw"
                />
              </div>
              <img src={Arch} alt="Arch Bot" className="arch-bot" />
              <div className="card-wrapper">
                <Card
                  {...{ body, options, officer }}
                  stepOptions={stepOptions[validStep]}
                  setStepOptions={handleSetStepOptions}
                />
              </div>
            </div>
          </div>
        </Container>
      </Col>
    </GridContainer>
  );
};

export default OurProcess;
