import { theme } from 'src/styles/theme';
import styled from 'styled-components';
import * as Tooltip from '@radix-ui/react-tooltip';

export const ToolTipContent = styled(Tooltip.Content)<{
  type: 'calculator' | 'LO' | undefined;
}>`
  background-color: ${theme.colors.navy};
  padding: 12px;
  text-align: center;
  color: ${theme.colors.white};
  width: ${({ type }) => (type === 'calculator' ? `${336}px` : 'fit-content')};
  max-width: 33.6rem;
  border-radius: 12px;
  border: none;
  z-index: 10;
`;

export const ToolTipTrigger = styled(Tooltip.Trigger)<{ info?: string }>`
  display: ${({ info }) => (info !== undefined ? 'block' : 'none')};
  display: flex;
`;

export const Arrow = styled(Tooltip.Arrow)<{
  type: 'calculator' | 'LO' | undefined;
}>`
  fill: ${theme.colors.navy};
  width: 15px;
  height: 6px;
  margin-top: -1px;

  @media (max-width: ${theme.breakpoints.lg}) {
    margin-left: ${({ type }) => (type === 'calculator' ? `-10px` : 'unset')};
    width: 10px;
  }
`;
