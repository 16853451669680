import styled from 'styled-components';

export const SliderArrowsWrapper = styled.div`
  margin: 16px 5px;
  display: flex;
  justify-content: flex-end;
  .arrow-flex {
    display: flex;
    align-items: center;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
    border-radius: 2.4rem;
    width: max-content;
    .arrow-icon {
      width: 4rem;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.4rem;
      border-radius: 2rem;
      transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
      &:hover {
        background-color: #ddd;
      }
      svg {
        font-size: 1.2rem;
        color: #1a2136;
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 0;
  }
`;
