import styled from 'styled-components';
import { GridContainer } from 'components/lib';

export const StyledContainer = styled(GridContainer)`
  margin-top: 188px;
  .title-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .bow-image {
    margin-top: -15px;
  }

  .bow-image-mobile,
  .see-all-mobile,
  .card-container-mobile {
    display: none;
  }
  .carousel-wrapper {
    padding-left: 118px;
    padding-bottom: 4rem;
    display: flex;
    overflow: hidden;
    .card-container {
      min-width: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 124px;
    margin-bottom: unset;
    padding-left: 66px;

    .carousel-wrapper {
      padding-left: 140px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 128px;
    margin-bottom: unset;
    padding-left: 16px;

    .title-wrapper {
      a {
        display: none;
      }
    }
    .carousel-wrapper {
      display: none;
    }
    .bow-image {
      display: none;
    }
    .see-all-mobile {
      display: block;
      margin-top: 20px;
    }
    .card-container-mobile {
      display: flex;
      margin-top: 16px;
      flex-direction: column;
      gap: 16px;
      width: 100%;
    }
    .bow-image-mobile {
      display: flex;
      width: 100% !important;
      justify-content: flex-end;
      padding-right: 20px;
      width: 312px;
    }
  }
`;
