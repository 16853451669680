const testimonialsLang = [
  {
    name: 'Kristin M.',
    location: 'Fridley, MN',
    testimonial:
      'Our loan officer was great to work with an extremely responsive and explained the process well.',
  },
  {
    name: 'Michelle M.',
    location: 'Cotopaxi, CO',
    testimonial:
      'Very streamlined which made the entire process pleasurable. Everyone we dealt with was very attentive and helpful.',
  },
  {
    name: 'Emily A.',
    location: 'Dallas, TX',
    testimonial: 'Our loan officer was wonderful. Very helpful.',
  },
  {
    name: 'Anthony H.',
    location: 'Spanish Fort, AL',
    testimonial: 'Great Service!',
  },
  {
    name: 'Brooke G.',
    location: 'Kyle, TX',
    testimonial:
      'Very responsive and went above and beyond to explain everything. We would definitely recommend.',
  },
];

export default { testimonialsLang };
