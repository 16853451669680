import styled from 'styled-components';

export const Container = styled.div<{ isChanging: boolean }>`
  .our-process {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 70px 0;
    .steps {
      max-width: 571px;
      width: 100%;
      h2 {
        margin-bottom: 32px;
      }
      margin-right: 78px;

      .dropdown {
        margin-bottom: 8rem;
        h4 {
          color: ${({ theme }) => theme.colors.navyLight};
        }
      }
    }
    .content {
      margin-top: 72px;
      position: relative;
      max-width: 474px;
      align-self: stretch;
      flex-grow: 1;
      .arch-bot {
        position: absolute;
        bottom: -14px;
        right: 0px;
      }
      .bg-img {
        width: 100%;
        height: 100%;
        max-width: 454px;
        min-height: 704px;
        position: relative;
        clip-path: circle(${({ isChanging }) => (isChanging ? '0%' : '100%')});
        transition: ease clip-path 0.5s;
        border-radius: 148px 0px;
        overflow: hidden;
        img {
          object-fit: cover;
        }
      }
      &::before {
        background-image: url('/img/diagonals-big.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;
        content: '';
        width: 330px;
        height: 246px;
        left: -218px;
        bottom: 118px;
      }
      .card-wrapper {
        position: absolute;
        top: 104px;
        left: -117px;
      }
    }
    @media (max-width: 1280px) {
      .steps {
        max-width: 330px;
        margin-right: 0;
      }
      .content {
        max-width: 323px;
        margin-top: 210px;
        .bg-img {
          min-height: 570px;
        }
        .arch-bot {
          bottom: -18px;
          right: -18px;
        }
        .card-wrapper {
          top: 62px;
          left: -66px;
        }
        ::before {
          left: -105px;
          bottom: 78px;
        }
      }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      .steps {
        .dropdown {
          margin-bottom: 3rem;
          h4 {
            font-size: 2.4rem;
          }
        }
      }
      .content {
        .bg-img,
        .arch-bot,
        ::before {
          display: none;
        }
        .card-wrapper {
          position: static;
        }
      }
    }
    @media (max-width: 640px) {
      .steps {
        max-width: none;
      }
      .content {
        display: none;
      }
    }
  }
`;
