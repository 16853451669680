import styled from 'styled-components';

export const Container = styled.div`
  .education-wrapper {
    border-radius: 1.6rem;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    :hover {
      box-shadow: none;
    }
    .education-img {
      border-top-left-radius: 1.6rem;
      border-top-right-radius: 1.6rem;
      height: 18rem;
      width: 100%;
      overflow: hidden;

      position: relative;
    }
    .education-content {
      padding: 3.2rem;
      padding-bottom: 2rem;
      .education-title {
        margin-bottom: 0.8rem;
        min-height: 64px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      .education-desc {
        font-size: 1.6rem;
        color: #1b2036;
        line-height: 2.4rem;
        margin-top: 0;
        margin-bottom: 3.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .link-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .link-text {
          color: var(--primary-green);
          font-size: 1.6rem;
          line-height: 2rem;
          font-weight: 600;
        }
        button > span > span {
          z-index: inherit;
        }
      }
    }
  }
`;
