import SliderArrows from 'components/commons/SliderArrows/SliderArrows';
import EducationCard from 'components/allEducation/EducationCard/EducationCard';
import { useCallback, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  Education,
  GeneralCard as General,
  SliderType,
} from 'src/common/types';
import { CardWrapper, StyledSliderContent } from './Slider.styles';
import GeneralCard from '../GeneralCard/GeneralCard';

interface SliderProps {
  articleCards?: Education[];
  generalCards?: General[];
  sliderSettings?: Settings;
  type: SliderType;
}

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  vertical: false,
  responsive: [
    {
      breakpoint: 1290,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1.7,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1.5,
      },
    },
  ],
};

const StyledSlider = ({
  articleCards,
  generalCards,
  sliderSettings,
  type,
}: SliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slider = useRef<Slider>(null);

  const handlePrev = useCallback(() => {
    slider.current?.slickPrev();
  }, []);

  const handleNext = useCallback(() => {
    slider.current?.slickNext();
  }, []);

  const afterChangeHandler = useCallback((currentSlider: number) => {
    setCurrentIndex(currentSlider);
  }, []);

  const checkIsEnd = useCallback(() => {
    if (typeof window !== 'undefined') {
      const thingsCount = generalCards?.length || articleCards?.length || 0;
      const width = window.innerWidth;
      const ss = sliderSettings || settings;

      const resIdx = ss.responsive?.findIndex(
        (item: any) => item.breakpoint < width
      );

      if (width < 480) {
        return currentIndex === thingsCount - 1;
      }

      if (resIdx === 0 && ss.slidesToShow) {
        return currentIndex === thingsCount - ss.slidesToShow;
      }
      if (resIdx && resIdx > 0) {
        const responsiveItem = ss.responsive ? ss.responsive[resIdx - 1] : null;
        if (responsiveItem?.settings !== 'unslick') {
          const slidesToShow = responsiveItem?.settings.slidesToShow || 0;
          if (slidesToShow === 1) {
            return currentIndex === thingsCount - 1;
          }
          return currentIndex === thingsCount - slidesToShow;
        }
      }
    }
    return false;
  }, [
    currentIndex,
    generalCards?.length,
    articleCards?.length,
    sliderSettings,
  ]);

  return (
    <>
      <SliderArrows
        handleNext={handleNext}
        handlePrev={handlePrev}
        isFirst={currentIndex === 0}
        isLast={checkIsEnd()}
      />
      <StyledSliderContent
        {...settings}
        {...sliderSettings}
        ref={slider}
        arrows={false}
        afterChange={afterChangeHandler}
      >
        {type === 'article' &&
          articleCards?.map((item: Education) => {
            return (
              <CardWrapper key={item.title}>
                <EducationCard {...item} />
              </CardWrapper>
            );
          })}
        {type === 'general' &&
          generalCards?.map((item: General) => {
            return (
              <CardWrapper key={item.title}>
                <GeneralCard {...item} size="336px" />
              </CardWrapper>
            );
          })}
      </StyledSliderContent>
    </>
  );
};

export default StyledSlider;
