import styled from 'styled-components';

export const ArrowLinkContent = styled.a`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green};
  &:hover {
    cursor: pointer;
  }
`;
