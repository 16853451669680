import React, { ReactElement, useState } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { ToolTipText } from 'components/lib';
import useWindowSize from 'src/hooks/useWindowSize';

import { Arrow, ToolTipContent, ToolTipTrigger } from './ToolTip.style';
import InfoIcon from '../svg/InfoIcon';

interface TooltipProps {
  info?: string;
  trigger?: ReactElement;
  type?: 'calculator' | 'LO';
  align?: 'start' | 'center' | 'end';
}

const ToolTip = ({ info, trigger, type, align }: TooltipProps) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const [open, setOpen] = useState(false);
  return (
    <Tooltip.Provider delayDuration={100}>
      <Tooltip.Root open={isMobile ? open : undefined}>
        <ToolTipTrigger info={info} asChild>
          <button
            onClick={() => {
              setOpen(prev => !prev);
            }}
            type="button"
          >
            {trigger || <InfoIcon />}
          </button>
        </ToolTipTrigger>
        <Tooltip.Portal>
          <ToolTipContent
            side="top"
            type={type}
            align={align || 'end'}
            onPointerDownOutside={() => setOpen(false)}
          >
            <ToolTipText dangerouslySetInnerHTML={{ __html: info || '' }} />
            <Arrow type={type} />
          </ToolTipContent>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default ToolTip;
