import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SliderArrowsWrapper } from './slider-arrows.styles';

interface SliderArrowsProps {
  handlePrev?: () => void;
  handleNext?: () => void;
  isFirst?: boolean;
  isLast?: boolean;
}

const SliderArrows = ({
  handlePrev,
  handleNext,
  isFirst,
  isLast,
}: SliderArrowsProps) => {
  return (
    <SliderArrowsWrapper>
      <div className="arrow-flex">
        <button
          onClick={handlePrev}
          type="button"
          className="arrow-icon"
          style={{
            opacity: isFirst ? 0.5 : 1,
            cursor: isFirst ? 'not-allowed' : 'pointer',
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          onClick={handleNext}
          type="button"
          className="arrow-icon"
          disabled={isLast}
          style={{
            opacity: isLast ? 0.5 : 1,
            cursor: isLast ? 'not-allowed' : 'pointer',
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </SliderArrowsWrapper>
  );
};

export default SliderArrows;
