import { ProcessOptions } from 'src/common/types';

export const Lang = {
  Purchase: {
    title: 'Our purchase process & what you’ll need',
    dropdownSubtitle: "process & what you'll need",
    steps: [
      {
        title: 'Conversation',
        body:
          'You’ll connect with a Keller Home Loans loan officer and we’ll discuss your goals and talk about loan options.',
        options: ['Short- and long-term goals', 'High-level budget'],
        image: '/img/home-page/our-process/step1.png',
      },
      {
        title: 'Application',
        body:
          'This step kicks off the loan process. Documentation will be required to get a full picture of your financial situation. Don’t worry, we’ll walk you through what is needed.',
        options: [
          'Debt documentation',
          'Asset documentation',
          'Income documentation',
        ],
        image: '/img/home-page/our-process/step2.png',
      },
      {
        title: 'Offer Ready pre-approval',
        body:
          'Your credit will be pulled and an underwriter will review and conditionally approve you for a loan amount and program.',
        options: [
          'Underwriter review',
          'Underwriter conditionally approve',
          'Awarded Offer Ready pre-approval',
        ],
        image: '/img/home-page/our-process/step3.png',
      },
      {
        title: 'House hunting + offer',
        body:
          'Now’s the time to find your dream home and make an offer! You’ll work with your real estate agent to help you structure the offer in the best way possible.',
        options: ['Find dream home', 'Make an offer', 'Offer accepted'],
        image: '/img/home-page/our-process/step4.png',
      },
      {
        title: 'Approval + inspection',
        body:
          'All information will be reviewed by a loan processor to verify for accuracy. The final underwriting process will approve or deny the loan. Meanwhile your home will undergo an appraisal and inspection.',
        options: [
          'Processor’s review',
          'Underwriter’s review',
          'Inspection',
          'Appraisal',
        ],
        image: '/img/home-page/our-process/step5.png',
      },
      {
        title: 'Closing',
        body:
          'Closing documents will be prepared and then provided to the closing agent for your signature. Your closing appointment usually occurs within five days of loan approval.',
        options: ['Clear-to-close', 'Closing date', 'Move in!'],
        image: '/img/home-page/our-process/step6.png',
      },
    ],
  },
  Refinance: {
    title: 'Our purchase process & what you’ll need',
    dropdownSubtitle: 'dummy refinance',
    steps: [
      {
        title: 'Refinance step 1',
        body:
          'Your credit will be pulled and an underwriter will review and conditionally approve you for a loan amount and program.',
        options: [
          'Underwriter review',
          'Underwriter conditionally approve',
          'Awarded Offer Ready pre-approval',
        ],
        image: '/img/home-page/our-process/step3.png',
      },
      {
        title: 'Refinance step 2',
        body:
          'Now’s the time to find your dream home and make an offer! You’ll work with your real estate agent to help you structure the offer in the best way possible.',
        options: ['Find dream home', 'Make an offer', 'Offer accepted'],
        image: '/img/home-page/our-process/step4.png',
      },
      {
        title: 'Refinance step 3',
        body:
          'All information will be reviewed by a loan processor to verify for accuracy. The final underwriting process will approve or deny the loan. Meanwhile your home will undergo an appraisal and inspection.',
        options: [
          'Processor’s review',
          'Underwriter’s review',
          'Inspection',
          'Appraisal',
        ],
        image: '/img/home-page/our-process/step5.png',
      },
      {
        title: 'Refinance step 4',
        body:
          'Closing documents will be prepared and then provided to the closing agent for your signature. Your closing appointment usually occurs within five days of loan approval.',
        options: ['Clear-to-close', 'Closing date', 'Move in!'],
        image: '/img/home-page/our-process/step6.png',
      },
    ],
  },
  CashOut: {
    title: 'Our purchase process & what you’ll need',
    dropdownSubtitle: 'dummy cash out',
    steps: [
      {
        title: 'Cash out step 1',
        body:
          'Now’s the time to find your dream home and make an offer! You’ll work with your real estate agent to help you structure the offer in the best way possible.',
        options: ['Find dream home', 'Make an offer', 'Offer accepted'],
        image: '/img/home-page/our-process/step4.png',
      },
      {
        title: 'Cash out step 2',
        body:
          'All information will be reviewed by a loan processor to verify for accuracy. The final underwriting process will approve or deny the loan. Meanwhile your home will undergo an appraisal and inspection.',
        options: [
          'Processor’s review',
          'Underwriter’s review',
          'Inspection',
          'Appraisal',
        ],
        image: '/img/home-page/our-process/step5.png',
      },
      {
        title: 'Cash out step 3',
        body:
          'Closing documents will be prepared and then provided to the closing agent for your signature. Your closing appointment usually occurs within five days of loan approval.',
        options: ['Clear-to-close', 'Closing date', 'Move in!'],
        image: '/img/home-page/our-process/step6.png',
      },
    ],
  },
};

export const Options: { value: ProcessOptions; label: string }[] = [
  { value: 'Purchase', label: 'Purchase' },
  { value: 'Refinance', label: 'Refinance' },
  { value: 'CashOut', label: 'Cash out' },
];
