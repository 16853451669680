import styled from 'styled-components';

export const Content = styled.div`
  padding: 100px 0;
  position: relative;
  .arrow-left,
  .arrow-right {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 64px;
    height: 64px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50%;
    svg {
      font-size: 26px;
      color: ${({ theme }) => theme.colors.navy};
    }
  }
  .arrow-right {
    right: 0;
  }
  .slider-wrapper {
    padding: 0 115px;
    .slick-prev,
    .slick-next {
      display: none;
    }
    .slider-item {
      display: flex;
      justify-content: center;
      align-items: center;
      .testimonial-wrapper {
        min-height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
      }
      .quote-img {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        position: relative;
        img.user {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          object-fit: cover;
          z-index: 1;
        }
        img.quote {
          width: 96px;
        }
        &::before {
          position: absolute;
          background: url('/img/home-page/testimonials/quote_diagonals.svg')
            no-repeat;
          background-size: cover;
          content: '';
          height: 46px;
          width: calc(100% - 232px);
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-weight: bold;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 2px;
        }

        h5 {
          text-align: center;
        }
        p {
          text-align: center;
          color: ${({ theme }) => theme.colors.navy};
        }
      }
      .quote-mobile {
        display: none;
      }
    }
  }
  .apply {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    button {
      width: 246px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: unset;
    padding-bottom: 96px;
    .arrow-left,
    .arrow-right {
      width: 52px;
      height: 52px;
      svg {
        font-size: 21px;
      }
    }
    .slider-wrapper {
      padding: 0 84px;
      .slider-item {
        .testimonial-wrapper {
          margin-bottom: 0;
        }
        .quote-img {
          img.quote {
            width: 72px;
          }
        }
      }
      .info {
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 96px 0;
    .arrow-left,
    .arrow-right {
      width: 56px;
      height: 56px;
      svg {
        font-size: 20px;
      }
    }
    .slider-wrapper {
      padding: 0 64px;
      .slider-item {
        .testimonial-wrapper {
          min-height: 128px;
        }
        .quote-img {
          justify-content: center;
          .quote {
            display: none;
          }
          ::before {
            display: none;
          }
        }
        .quote-mobile {
          width: 52px;
          margin-bottom: 20px;
          display: block;
          margin: auto;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export const TestimonialInfo = styled.button<{ openTestimonial: boolean }>`
  overflow: hidden;
  p {
    text-overflow: ellipsis;
    font-family: proxima-nova, sans-serif;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: ${({ theme }) => theme.colors.navy};
    margin: 0;
    font-weight: 300;
    display: -webkit-box;
    -webkit-line-clamp: ${({ openTestimonial }) =>
      openTestimonial ? 'unset' : '8'};
    line-clamp: ${({ openTestimonial }) => (openTestimonial ? 'unset' : '8')};
    -webkit-box-orient: vertical;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    p {
      font-size: 32px;
      line-height: 40px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    p {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;
