import React from 'react';
import NextLink from 'next/link';
import ArrowRight from '../svg/ArrowRight';
import { ArrowLinkContent } from './ArrowLink.style';

interface ArrowLinkProps {
  href: string;
  title: string;
}
function ArrowLink({ href, title }: ArrowLinkProps) {
  return (
    <NextLink href={href} legacyBehavior>
      <ArrowLinkContent>
        <span>{title}</span>
        <ArrowRight />
      </ArrowLinkContent>
    </NextLink>
  );
}

export default ArrowLink;
