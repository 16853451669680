import React from 'react';

const InfoIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33398 3.66683H7.66732V5.00016H6.33398V3.66683ZM6.33398 6.3335H7.66732V10.3335H6.33398V6.3335ZM7.00065 0.333496C3.32065 0.333496 0.333984 3.32016 0.333984 7.00016C0.333984 10.6802 3.32065 13.6668 7.00065 13.6668C10.6807 13.6668 13.6673 10.6802 13.6673 7.00016C13.6673 3.32016 10.6807 0.333496 7.00065 0.333496ZM7.00065 12.3335C4.06065 12.3335 1.66732 9.94016 1.66732 7.00016C1.66732 4.06016 4.06065 1.66683 7.00065 1.66683C9.94065 1.66683 12.334 4.06016 12.334 7.00016C12.334 9.94016 9.94065 12.3335 7.00065 12.3335Z"
        fill="#1A2136"
      />
    </svg>
  );
};

export default InfoIcon;
