import { ReactNode, useCallback, useRef, useState } from 'react';
import Slider from 'react-slick';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, GridContainer, H3, Paragraph } from 'components/lib';
import lang from 'src/data/lang/testimonials.lang';
import quoteLeft from 'public/img/home-page/testimonials/quote_left.png';
import quoteRight from 'public/img/home-page/testimonials/quote_right.png';
import ApplyNowButton from 'components/commons/ApplyButton/ApplyButton';
import { LoanOfficer, TestimonialsData } from 'src/common/types';
import { Content, TestimonialInfo } from './testimonials.styles';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
};
const Testimonials = ({
  officer,
  actionButton,
  testimonials = lang.testimonialsLang,
  expandable,
  speed,
}: {
  officer?: LoanOfficer;
  actionButton?: ReactNode;
  testimonials?: TestimonialsData[];
  expandable?: boolean;
  speed?: number;
}) => {
  const { firstName } = officer || {};
  const [openTestimonial, setOpenTestimonial] = useState(-1);
  const slider = useRef<Slider>(null);

  const handlePrev = useCallback(() => {
    slider.current?.slickPrev();
    if (expandable) {
      setOpenTestimonial(-1);
    }
  }, [expandable]);

  const handleNext = useCallback(() => {
    slider.current?.slickNext();
    if (expandable) {
      setOpenTestimonial(-1);
    }
  }, [expandable]);

  return (
    <GridContainer>
      <Col span="2/12" smallSpan="1/13" mediumSpan="1/13">
        <Content>
          <button className="arrow-left" onClick={handlePrev} type="button">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button className="arrow-right" onClick={handleNext} type="button">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
          <div className="slider-wrapper">
            <Slider
              {...settings}
              autoplaySpeed={speed || 5000}
              ref={slider}
              arrows={false}
            >
              {testimonials.map((tml, index) => {
                const { name, title, testimonial, location } = tml;
                return (
                  <div className="slider-item" key={name.trim()}>
                    <img
                      src={quoteLeft}
                      alt="quote left"
                      className="quote-mobile"
                    />
                    <div className="testimonial-wrapper">
                      <TestimonialInfo
                        openTestimonial={
                          expandable ? openTestimonial === index : true
                        }
                        className="testimonial"
                        onClick={() => {
                          if (expandable) {
                            setOpenTestimonial(prev =>
                              prev === index ? -1 : index
                            );
                          }
                        }}
                      >
                        <p>{testimonial}</p>
                      </TestimonialInfo>
                    </div>
                    <div className="quote-img">
                      <img src={quoteLeft} alt="quote left" className="quote" />
                      <div className="info">
                        <H3>{name}</H3>
                        {title && (
                          <Paragraph className="title">{title}</Paragraph>
                        )}
                        <Paragraph>{location}</Paragraph>
                      </div>
                      <img
                        src={quoteRight}
                        alt="quote right"
                        className="quote"
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="apply">
            {actionButton || (
              <ApplyNowButton lo={officer}>
                {firstName ? `Apply with ${firstName}` : `Apply Now`}
              </ApplyNowButton>
            )}
          </div>
        </Content>
      </Col>
    </GridContainer>
  );
};

export default Testimonials;
