import React from 'react';
import {
  faArrowRight,
  faArrowDown,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { H4, H7 } from 'components/lib';
import Card, { CardProps } from '../Card';
import { Container } from './styles';

interface StepProps {
  title: string;
  current: number;
  active: number;
  onSelect: (idx: number) => void;
  card: CardProps;
}

const Step = ({ title, current, active, onSelect, card }: StepProps) => {
  const isActive = current === active;
  return (
    <Container isActive={isActive}>
      <div className="wrapper">
        <button
          type="button"
          onClick={() => onSelect(current)}
          className="step-button"
        >
          <H7 as="span" className="step-number">
            Step {current + 1}
          </H7>
          <H4 as="span" className="step-title">
            {title}
          </H4>
        </button>
        <FontAwesomeIcon icon={faArrowRight} className="arrow-right" />
        {!isActive && (
          <FontAwesomeIcon icon={faArrowDown} className="arrow-down" />
        )}
        {isActive && <FontAwesomeIcon icon={faArrowUp} className="arrow-up" />}
      </div>
      <div className="card-wrapper">
        <Card {...card} isActive={isActive} />
      </div>
    </Container>
  );
};

export default Step;
