import React from 'react';

function DownArch() {
  return (
    <svg
      width="156"
      height="156"
      viewBox="0 0 156 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-1.3638e-05 2.72759e-05C-1.1847e-05 20.4862 4.03505 40.7719 11.8748 59.6986C19.7145 78.6254 31.2054 95.8227 45.6913 110.309C60.1773 124.795 77.3746 136.285 96.3014 144.125C115.228 151.965 135.514 156 156 156L156 124.8C139.611 124.8 123.383 121.572 108.241 115.3C93.0997 109.028 79.3418 99.8357 67.7531 88.247C56.1643 76.6582 46.9716 62.9004 40.6998 47.7589C34.428 32.6175 31.2 16.389 31.2 2.45483e-05L-1.3638e-05 2.72759e-05Z"
        fill="#9AD8B5"
      />
    </svg>
  );
}

export default DownArch;
