import React from 'react';

function Topic() {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.667 3.00065H14.0003L11.3337 0.333984H3.33366C1.86699 0.333984 0.680326 1.53398 0.680326 3.00065L0.666992 19.0007C0.666992 20.4673 1.86699 21.6673 3.33366 21.6673H25.6937C26.6003 21.6673 27.3337 20.9207 27.3337 20.0273V5.66732C27.3337 4.20065 26.1337 3.00065 24.667 3.00065ZM24.667 19.0007H3.33366V3.00065H10.227L12.8937 5.66732H24.667V19.0007ZM22.0003 11.0007H6.00033V8.33398H22.0003V11.0007ZM16.667 16.334H6.00033V13.6673H16.667V16.334Z"
        fill="#1A2136"
      />
    </svg>
  );
}

export default Topic;
