import Topic from 'components/commons/svg/Topic';

const title = 'Learn about what to expect';
const seeAll = 'See all';
const whatToExpectContent = [
  {
    Icon: Topic,
    title: 'All of our current offers',
    description: 'Our programs and products',
    href: '/offers',
  },
  {
    Icon: Topic,
    title: 'All education materials',
    description: 'Things to consider along the way',
    href: '/all-education',
  },
  {
    Icon: Topic,
    title: 'Mortgage definitions',
    description: 'Helping you understand ',
    href: 'mortgage-definitions',
  },
  {
    Icon: Topic,
    title: 'Learn the market and jargon',
    description: 'Our terminology can be confusing',
    href: '/jargon-market',
  },
  {
    Icon: Topic,
    title: 'Find a Loan Officer',
    description: 'Our experts are here for you',
    href: 'loan-officers',
  },
];

export default { whatToExpectContent, title, seeAll };
