import { useCallback } from 'react';
import CircleCheck from 'public/img/home-page/our-process/circleCheck.svg';
import { H7, Paragraph } from 'components/lib';
import ApplyNowButton from 'components/commons/ApplyButton/ApplyButton';
import { LoanOfficer } from 'src/common/types';
import { Option, Wrapper } from './styles';

export interface CardProps {
  body: string;
  options: string[];
  isActive?: boolean;
  stepOptions: boolean[];
  setStepOptions: (stepOptions: boolean[]) => void;
  officer?: LoanOfficer;
}

const Card = ({
  body,
  options,
  isActive,
  stepOptions,
  setStepOptions,
  officer,
}: CardProps) => {
  const { firstName } = officer || {};

  const handleSelectOption = useCallback(
    (idx: number) => {
      const tmpOptions = [...stepOptions];
      tmpOptions[idx] = !tmpOptions[idx];
      setStepOptions(tmpOptions);
    },
    [stepOptions, setStepOptions]
  );

  return (
    <Wrapper isActive={isActive}>
      <Paragraph>{body}</Paragraph>
      <H7>What May Happen</H7>
      {options.map((option, idx) => {
        return (
          <Option
            key={option.trim()}
            selected={stepOptions[idx]}
            onClick={() => handleSelectOption(idx)}
          >
            {stepOptions[idx] ? (
              <img src={CircleCheck} alt="circle check" />
            ) : (
              <div className="circle" />
            )}
            <div className="option-txt">
              <Paragraph>{option}</Paragraph>
            </div>
          </Option>
        );
      })}
      <ApplyNowButton className="apply" lo={officer}>
        {firstName ? `Apply with ${firstName}` : `Apply Now`}
      </ApplyNowButton>
    </Wrapper>
  );
};

export default Card;
