import { Col, SectionTitle } from 'components/lib';
import StyledSlider from 'components/commons/Slider/Slider';
import ArrowLink from 'components/commons/ArrowLink/ArrowLink';
import lang from 'src/data/lang/learn-about-what-to-expect.lang';
import DownArch from 'components/commons/svg/DownArch';
import Arch from 'components/commons/svg/Arch';
import GeneralCard from 'components/commons/GeneralCard/GeneralCard';
import { GeneralCard as General } from 'src/common/types';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { StyledContainer } from './learn-about-what-to-expect.styles';

const LearnAboutWhatToExpect = () => {
  const { title, whatToExpectContent, seeAll } = lang;
  return (
    <StyledContainer>
      <Col span="2/12" smallSpan="1/13" mediumSpan="1/13">
        <div className="title-wrapper">
          <SectionTitle className="carousel-title">{title}</SectionTitle>
          <ArrowLink href="" title={seeAll} />
        </div>
        <div className="carousel-wrapper">
          <div className="card-container">
            <StyledSlider generalCards={whatToExpectContent} type="general" />
          </div>
        </div>
        <div className="bow-image">
          <DownArch />
        </div>
        <div className="card-container-mobile">
          {whatToExpectContent.map((item: General) => (
            <GeneralCard {...item} size="336px" key={item.title} />
          ))}
        </div>
        <div className="see-all-mobile">
          <ArrowLink href="" title={seeAll} />
        </div>
        <div className="bow-image-mobile">
          <Arch />
        </div>
      </Col>
    </StyledContainer>
  );
};

export default LearnAboutWhatToExpect;
