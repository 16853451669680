import React from 'react';

const Arch = () => {
  return (
    <svg
      width="157"
      height="156"
      viewBox="0 0 157 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.499986 156C20.9862 156 41.2718 151.965 60.1986 144.125C79.1254 136.285 96.3227 124.795 110.809 110.309C125.295 95.8227 136.785 78.6254 144.625 59.6986C152.465 40.7718 156.5 20.4862 156.5 0L125.3 -1.3638e-06C125.3 16.389 122.072 32.6175 115.8 47.7589C109.528 62.9003 100.336 76.6582 88.7469 88.2469C77.1582 99.8357 63.4003 109.028 48.2589 115.3C33.1175 121.572 16.889 124.8 0.499988 124.8L0.499986 156Z"
        fill="#AAE2C3"
      />
    </svg>
  );
};

export default Arch;
