import Select from 'react-select';
import { ProcessOptions } from 'src/common/types';
import { Options } from 'src/data/lang/our-process-what-need.lang';
import { ProcessTypeSelectContainer } from './ProcesssTypeSelect.styles';

const ProcesssTypeSelect = ({
  setContent,
}: {
  setContent: (option: ProcessOptions) => void;
}) => {
  return (
    <ProcessTypeSelectContainer>
      <Select
        options={Options}
        className="process-type-select"
        onChange={option => {
          if (option) setContent(option.value);
        }}
        classNamePrefix="process-type-select"
        defaultValue={Options[0]}
      />
    </ProcessTypeSelectContainer>
  );
};

export default ProcesssTypeSelect;
